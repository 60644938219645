import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";

const getMeetingData = async ({
  page = 1,
  limit,
  status,
  search,
  pagination = true,
  srManager,
  manager,
  assistantManager,
  teamLead,
  agent,
  startDate,
  endDate,
}) => {
  try {
    const { data } = await API_AXIOS.get(Apis.meeting, {
      params: {
        page,
        limit,
        status,
        search,
        pagination,
        srManager,
        manager,
        assistantManager,
        teamLead,
        agent,
        startDate,
        endDate,
      },
    });
    return { data };
  } catch (error) {
    return {
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const meetingsApi = createApi({
  reducerPath: "meetingList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Meetings"],
  endpoints: (builder) => ({
    getMeetingsData: builder.query({
      // query: ({
      //   page = 1,
      //   limit,
      //   status,
      //   search,
      //   pagination = true,
      //   srManager,
      //   manager,
      //   assistantManager,
      //   teamLead,
      //   agent,
      //   startDate,
      //   endDate,
      // }) => ({
      //   url: Apis.meeting,
      //   params: {
      //     page,
      //     limit,
      //     status,
      //     search,
      //     pagination,
      //     srManager,
      //     manager,
      //     assistantManager,
      //     teamLead,
      //     agent,
      //     startDate,
      //     endDate,
      //   },
      // }),
      queryFn: getMeetingData,
      providesTags: ["Meetings"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetMeetingsDataQuery } = meetingsApi;
