import { Button,CircularProgress} from '@mui/material';
import React from 'react';



export const CustomBottom = ({ children,hoverColor, onClick, style, loading, Submit, ...props }) => {
  return (
    <Button {...props}
      onClick={onClick}
      sx={{
        color: "#FFFFFF",
        boxShadow: "5.625px 7.5px 9.375px 0px #0000003D",
        background: "#FFC857",
        borderRadius: "11px",
        fontWeight: "700",
        width: "fit-content",
        textTransform: "none",
        fontSize: "15px",
        "&:hover": {
          backgroundColor: hoverColor ? hoverColor : "#FFC857",
        },
        "@media(max-width: 900px)":{
           height: "100%",
           width: "fit-content",
           borderRadius: "5px",
           "@media(max-width: 600px)":{
           width: "fit-content",
         
        },
        },
        ...style
      }}
    >
      {loading ? <CircularProgress sx={{ padding: "8px" }} />: children}
    </Button>
  );
};
