import React, { useState } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BasicSelect from "./BasicSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectCatagory } from "../utils/menuItems";
import LeadAdForm from "./Lead/LeadAdForm";
import MeetingAdForm from "./Meeting/MeetingAdForm";
import BookingAdForm from "./Booking/BookingAdForm";
import { clearQuery } from "../store/reducers/query";

const AdvanceSearch = ({ handleClose, title }) => {
  const { type } = useSelector((state) => state.query);
  const [catagory, setCatagory] = useState(type ? type : "lead");
  const dispatch = useDispatch();

  const handleCatagory = (e) => {
    dispatch(clearQuery());
    const selectedCategory = e.target.value;
    setCatagory(selectedCategory);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            sx={{
              fontSize: { sm: "1rem", md: "1.5rem" },
              color: "#000000",
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
          <Divider
            sx={{
              backgroundColor: "#FFC857",
              width: "100%",
              margin: "0 auto",
            }}
          />
        </Box>
        <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{
                fontSize: 18,
                mb: 1,
                fontWeight: 500,
                color: "#000000",
              }}
            >
              Catagory
            </Typography>
            <BasicSelect
              name={"catagory"}
              value={catagory}
              size="small"
              handleChange={handleCatagory}
              items={selectCatagory}
            />
          </Grid>
        </Grid>

        {catagory === "lead" ? (
          <LeadAdForm handleClose={handleClose} />
        ) : catagory === "meeting" ? (
          <MeetingAdForm handleClose={handleClose} />
        ) : catagory === "booking" ? (
          <BookingAdForm handleClose={handleClose} />
        ) : null}
      </Box>
    </Box>
  );
};

export default AdvanceSearch;
