import dayjs from "dayjs";
import { clearQuery } from "../store/reducers/query";
import { clearAdvanceSearch } from "../store/reducers/queryData";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const formatDate = (date) => {
  return dayjs(date).format("DD/MM/YYYY");
};
export const formatTime = (date) => {
  return dayjs(date).format("hh:mm A");
};

export const closeQuery = (dispatch) => {
  dispatch(clearQuery());
  dispatch(clearAdvanceSearch());
};

export const downloadFile = (url) => {
  saveAs(url);
};

export const teamUsers = (teams = [], users = []) => {
  return users.filter((user) => {
    let InTeam = false;
    teams.forEach((team) => {
      if (team.srManager?._id === user._id) {
        InTeam = true;
        return true;
      } else if (team.manager?._id === user._id) {
        InTeam = true;
        return true;
      } else if (team.assistantManager?._id === user._id) {
        InTeam = true;
        return true;
      } else if (team.teamLead?._id === user._id) {
        InTeam = true;
        return true;
      } else if (team.agents && team.agents.length > 0) {
        team.agents.forEach((agent) => {
          if (agent._id === user._id) {
            InTeam = true;
            return true;
          }
        });
      }
    });

    if (InTeam) {
      return true;
    }
    return false;
  });
};

export const checkInTeam = (teams = [], user) => {
  let InTeam = false;
  const team = teams.filter((team) => {
    if (team.srManager?._id === user?._id) {
      InTeam = true;
      return true;
    } else if (team.manager?._id === user?._id) {
      InTeam = true;
      return true;
    } else if (team.assistantManager?._id === user?._id) {
      InTeam = true;
      return true;
    } else if (team.teamLead?._id === user?._id) {
      InTeam = true;
      return true;
    } else if (team.agents && team.agents?.length > 0) {
      team.agents?.forEach((ag) => {
        if (ag._id === user?._id) {
          InTeam = true;
          return true;
        }
      });
    }

    if (InTeam) {
      return true;
    }
    return false;
  });

  return team[0] || {};
};

export const jsonSort = (array = [], key, ascending = false) => {
  if (array) {
    return array.sort((a, b) => {
      const compareValue = ascending ? -1 : 1;

      if (a[key] < b[key]) {
        return compareValue;
      }
      if (a[key] > b[key]) {
        return -compareValue;
      }
      return 0;
    });
  }

  return [];
};

export const filterArr = (arr = [], key, value) => {
  console.log(key, value);
  return arr.filter((item) => {
    return item[key] === value;
  });
};

export const getAddressFromCoordinates = async (coordinates, key) => {
  const { lat, lng } = coordinates;
  try {
    if (!!lat) {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`
      );
      const json = await response.json();
      // setRealAddress(json.results[0]?.formatted_address);
      return json?.results[0]?.formatted_address;
    }
  } catch (error) {
    console.error("errLocation", error);
  }
};

export const customCsvDownload = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const csvData = XLSX.utils.sheet_to_csv(ws);
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create download link
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `download.csv`;

  // Append the link to the body
  document.body.appendChild(downloadLink);

  // Trigger the download
  downloadLink.click();

  // Remove the link from the DOM
  document.body.removeChild(downloadLink);
};

export const updateSearchParams = (setSearchParams, updates) => {
  setSearchParams((prevParams) => {
    const params = new URLSearchParams(prevParams);
    Object.entries(updates).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    return params;
  });
};

export function createParams(prevParams, updates) {
  const params = new URLSearchParams(prevParams);
  Object.entries(updates).forEach(([key, value]) => {
    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
  });
  return params.toString();
}

export function calculateBookingPercentage(commission, total) {
  const commissionValue = parseFloat(commission);
  const totalValue = parseFloat(total);

  if (isNaN(commissionValue) || isNaN(totalValue) || totalValue === 0) {
    return 0;
  }

  const commissionPercentage = (commissionValue / totalValue) * 100;
  return commissionPercentage.toFixed(2);
}

export function calculateBookingValues(commissionPerc, total) {
  const commissionPercentage = parseFloat(commissionPerc);
  const totalValue = parseFloat(total);

  if (isNaN(commissionPercentage) || isNaN(totalValue)) {
    return 0;
  }

  const commission = (commissionPercentage / 100) * totalValue;
  return commission.toFixed(2);
}

export function calculateNetCommission(
  commission,
  clientLoyalty,
  brokerReferral
) {
  const commissionValue = parseFloat(commission);
  const clientLoyaltyValue = parseFloat(clientLoyalty);
  const brokerReferralValue = parseFloat(brokerReferral);

  if (
    isNaN(commissionValue) ||
    isNaN(clientLoyaltyValue) ||
    isNaN(brokerReferralValue)
  ) {
    return 0;
  }

  return (commissionValue - (clientLoyaltyValue + brokerReferralValue)).toFixed(
    2
  );
}
