import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserData: builder.query({
      query: ({ page = 1, search }) => ({
        url: Apis.hrmsUser,
        params: {
          page,
          search,
        },
      }),
      providesTags: ["User"],
    //   transformResponse: (res) => res,
      keepUnusedDataFor: 180,
    }),
  }),
});

export const { useGetUserDataQuery } = userApi;
