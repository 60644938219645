import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";

export const latestAuthApi = createApi({
  reducerPath: "latestAuth",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["LatestAuth"],
  endpoints: (builder) => ({
    getLatestAuth: builder.query({
      query: (id) => `/hrms/user/getUserDetailsById/${id}`,
      providesTags: ["LatestAuth"],
    }),
  }),
});

export const { useGetLatestAuthQuery } = latestAuthApi;
