import { Box, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/HrManagements/Sidebar";

const HrmsMainLayout = () => {
  return (
    <Box sx={{ marginTop: "3rem" }}>
      <Box
        component="main"
        sx={{
          p: "2rem 1rem",
          display: "flex",
          "@media(max-width: 600px)": {
            p: "2.5rem  0.8rem",
          },
        }}
      >
        <Sidebar />
        
          {/* <Box
            sx={{
              color: "#222B45",
              fontSize: "20px",
              fontWeight: "800",
              display: { xs: "inherit", sm: "none" }, // Show on extra small screens (mobile) and hide on larger screens
              textAlign: "center",
            }}
          >
            sfjsjdb
          </Box> */}
          <Outlet />
        
      </Box>
    </Box>
  );
};

export default HrmsMainLayout;
