import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import CustomTextInput from "../../../components/HrManagements/CustomInput";
import CustomSelect from "../../../components/HrManagements/CustomSelect";
import { CustomBottom } from "../../../components/HrManagements/CustomBottom";
import { toast } from "react-toastify";
import { API_AXIOS_MULTIPART } from "../../../http/interceptor";
import { useSearchParams } from "react-router-dom";
import Apis from "../../../utils/apis";
import { relationOption } from "../../../utils/menuItems";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import * as Yup from "yup";

const EmergencyContact = ({ onNext, onPrev, user, isAdmin }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const token = searchParams.get("token");
  const [phoneError, setPhoneError] = useState("");
  const [values, setValues] = useState({
    emergency: {
      egcMobile: "",
      egcName: "",
      egcRelation: "",
    },
  });

  useEffect(() => {
    setValues((state) => ({
      emergency: {
        egcMobile: user?.emergency?.egcMobile || "",
        egcName: user?.emergency?.egcName || "",
        egcRelation: user?.emergency?.egcRelation || "",
      },
    }));
  }, [user]);

  const handleSelectChange = (event) => {
    const selectedRelation = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      emergency: {
        ...prevValues.emergency,
        egcRelation: selectedRelation,
      },
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [nestedObject, nestedProperty] = name.split(".");
    setValues((prevValues) => ({
      ...prevValues,
      [nestedObject]: {
        ...prevValues[nestedObject],
        [nestedProperty]: value,
      },
    }));
  };

  const handleSummit = async () => {
    //console.log("values", values);
    try {
      const emergencyData = {};
      user?.isEditable?.forEach((el) => {
        if (!!values[el]) {
          emergencyData[el] = values[el];
        } else {
          let noKey = ["emergency"];
          noKey?.forEach((x) => {
            for (const key in values[x]) {
              if (el === key) {
                let dd = {
                  [el]: values[x][key],
                };
                emergencyData[x] = { ...emergencyData[x], ...dd };
              }
            }
          });
        }
      });
      let updatingData = !user?.isFilled || isAdmin ? values : emergencyData;
      const formData = new FormData();
      formData.append("data", JSON.stringify(updatingData));
      formData.append("token", token);
      setLoading(true);
      const { data } = await API_AXIOS_MULTIPART.post(
        `${Apis.updateUser}/${id}`,
        formData
      );

      onNext();
    } catch (err) {
      console.log(err);
      toast.error(err.response.data);
    }
    setLoading(false);
  };

  const checkDisable = (key) => {
    if (isAdmin) return false;
    return user?.isFilled && !user?.isEditable?.includes(key);
  };

  return (
    <Box>
      <Grid container rowSpacing={4} columnSpacing={4} mt={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            EMERGENCY CONTACT
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Name"}
            name="emergency.egcName"
            value={values.emergency.egcName}
            placeholder={"Name"}
            onChange={handleInputChange}
            disabled={checkDisable("egcName")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {/* <CustomTextInput
             style={{width: "100%"}}
              label="Mobile Number"
              name="emergency.egcMobile"
              value={values.emergency.egcMobile}
              placeholder="Mobile Number"
              onChange={handleInputChange}
              disabled={
                user?.isFilled && !user?.isEditable?.includes("egcMobile")
              }
            /> */}

          <>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "15px",
                marginBottom: "2px",
                "@media(max-width:600px)": {
                  fontSize: "13px",
                },
              }}
            >
              Mobile Number
            </Typography>
            <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              defaultCountry="ae"
              placeholder="Mobile Number"
              value={values.emergency.egcMobile}
              onChange={(phone) =>
                handleInputChange({
                  target: { name: "emergency.egcMobile", value: phone },
                })
              }
              disabled={checkDisable("egcMobile")}
            />
            {/* {phoneError && (
              <Typography
                sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
              >
                {phoneError}
              </Typography>
            )} */}
          </>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Relation"}
            name="emergency.egcRelation"
            value={values.emergency.egcRelation}
            placeholder={"Relation"}
            onChange={handleInputChange}
            disabled={checkDisable("egcRelation")}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          <CustomSelect
            value={values.emergency.egcRelation}
            handleChange={handleSelectChange}
            label="Relation"
            items={relationOption}
            disabled={checkDisable("egcRelation")}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          "@media(max-width:  600px)": {
            gap: 2,
          },
        }}
      >
        <CustomBottom
          onClick={onPrev}
          hoverColor={{ backgroundColor: "#8F9BB3" }}
          style={{
            marginTop: "2rem",
            width: "25%",
            backgroundColor: "#8F9BB3",
            "@media(max-width: 900px)": {
              width: "40%",
              "@media(max-width: 600px)": {
                width: "90%",
              },
            },
          }}
        >
          Previous
        </CustomBottom>
        <CustomBottom
          style={{
            marginTop: "2rem",
            width: "25%",
            "@media(max-width: 900px)": {
              width: "40%",
              "@media(max-width: 600px)": {
                justifyContent: "center",
                width: "90%",
              },
            },
          }}
          loading={loading}
          onClick={handleSummit}
          // onClick={async () => {
          //   await handleSummit();
          //   onNext();
          // }}
        >
          Next
        </CustomBottom>
      </Box>
    </Box>
  );
};

export default EmergencyContact;
