import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import CustomTextInput from "../../../components/HrManagements/CustomInput";
import SimpleDatePicker from "../../../components/HrManagements/SimpleDatePicker";
import CustomSelect from "../../../components/HrManagements/CustomSelect";
import { CustomBottom } from "../../../components/HrManagements/CustomBottom";
import { Box } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { API_AXIOS_MULTIPART } from "../../../http/interceptor";
import Apis from "../../../utils/apis";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CustomFileInput from "../../../components/HrManagements/CustomFileInput";

const visaOption = [
  { value: "visit", label: "Visit" },
  { value: "employment", label: "Employment" },
  { value: "sponsor", label: "Sponsor" },
];

const UserIdProof = ({ onNext, onPrev, user, isAdmin }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const token = searchParams.get("token");
  const [passportFile, setPassportFile] = useState([]);
  const [visaFile, setVisaFile] = useState([]);
  const [resume, setResume] = useState([]);
  const [emiratesId, setEmiratesId] = useState([]);
  const [pancard, setPancard] = useState([]);
  const [aadharCard, setAadharCard] = useState([]);
  const [sscDuc, setSscDuc] = useState([]);
  const [hscDuc, setHscDuc] = useState([]);
  const [degreeCertificate, SetDegreeCertificate] = useState([]);

  const [values, setValues] = useState({
    passport: {
      passNumber: "",
      passDateOfIssue: "",
      passExpiry: "",
    },
    visa: {
      vType: "",
      vExpiry: "",
    },
  });

  useEffect(() => {
    setValues((state) => ({
      passport: {
        passNumber: user?.passport?.passNumber || "",
        passDateOfIssue: dayjs(user?.passport?.passDateOfIssue) || "",
        passExpiry: dayjs(user?.passport?.passExpiry) || "",
      },
      visa: {
        vType: user?.visa?.vType || "",
        vExpiry: dayjs(user?.visa?.vExpiry) || "",
      },
    }));
  }, [user]);

  const handleSelcted = (event) => {
    const selectedVisa = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      visa: {
        ...prevValues.visa,
        vType: selectedVisa,
      },
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [nestedObject, nestedProperty] = name.split(".");
    setValues((prevValues) => ({
      ...prevValues,
      [nestedObject]: {
        ...prevValues[nestedObject],
        [nestedProperty]: value,
      },
    }));
  };

  const handleSummit = async () => {
    try {
      const userIdData = {};
      user?.isEditable?.forEach((el) => {
        if (!!values[el]) {
          userIdData[el] = values[el];
        } else {
          let noKey = ["passport", "visa"];
          noKey?.forEach((x) => {
            for (const key in values[x]) {
              if (el === key) {
                let dd = {
                  [el]: values[x][key],
                };
                userIdData[x] = { ...userIdData[x], ...dd };
              }
            }
          });
        }
      });

      let updatingData = !user?.isFilled || isAdmin ? values : userIdData;
      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify(updatingData)
      );
      formData.append("token", token);
      formData.append("finalStage", true);
      const passportFileArr = Object?.values(passportFile) || [];
      const visaFileArr = Object?.values(visaFile) || [];
      const resumeFileArr = Object?.values(resume) || [];
      const emiratesIdFileArr = Object?.values(emiratesId) || [];
      const pancardFileArr = Object?.values(pancard) || [];
      const aadharCardFileArr = Object?.values(aadharCard) || [];
      const sscDucFileArr = Object?.values(sscDuc) || [];
      const hscDucFileArr = Object?.values(hscDuc) || [];
      const degreeCertificateFileArr = Object?.values(degreeCertificate) || [];

      passportFileArr?.forEach((passport) => {
        formData.append("passportFile", passport);
      });
      visaFileArr?.forEach((visa) => {
        formData.append("visaFile", visa);
      });
      resumeFileArr?.forEach((resume) => {
        formData.append("resume", resume);
      });
      emiratesIdFileArr?.forEach((emiratesId) => {
        formData.append("emiratesId", emiratesId);
      });
      pancardFileArr?.forEach((pancard) => {
        formData.append("pancard", pancard);
      });
      aadharCardFileArr?.forEach((aadharCard) => {
        formData.append("aadharCard", aadharCard);
      });
      sscDucFileArr?.forEach((sscDuc) => {
        formData.append("sscDuc", sscDuc);
      });
      hscDucFileArr?.forEach((hscDuc) => {
        formData.append("hscDuc", hscDuc);
      });
      degreeCertificateFileArr?.forEach((degreeCertificate) => {
        formData.append("degreeCertificate", degreeCertificate);
      });


      setLoading(true);
      const { data } = await API_AXIOS_MULTIPART.post(
        `${Apis.updateUser}/${id}`,
        formData
      );
      toast.success(data.message);
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data);
    }
    setLoading(false);
  };

  const checkDisable = (key) => {
    if (isAdmin) return false;
    return user?.isFilled && !user?.isEditable?.includes(key);
  };

  return (
    <>
      <Grid container rowSpacing={4} columnSpacing={4} mt={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            ID PROOF
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Passport Number"}
            name="passport.passNumber"
            value={values.passport.passNumber}
            onChange={handleInputChange}
            placeholder={"Passport Number"}
            disabled={checkDisable("passNumber")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SimpleDatePicker
            label={"Issue Date"}
            value={values.passport.passDateOfIssue}
            onChange={(newValue) =>
              setValues((prevValues) => ({
                ...prevValues,
                passport: {
                  ...prevValues.passport,
                  passDateOfIssue: newValue,
                },
              }))
            }
            disabled={checkDisable("passDateOfIssue")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SimpleDatePicker
            label={"Expiry Date"}
            value={values.passport.passExpiry}
            onChange={(newValue) =>
              setValues((prevValues) => ({
                ...prevValues,
                passport: {
                  ...prevValues.passport,
                  passExpiry: newValue,
                },
              }))
            }
            disabled={checkDisable("passExpiry")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomSelect
            value={values?.visa?.vType}
            handleChange={handleSelcted}
            label="Visa Type"
            items={visaOption}
            disabled={checkDisable("vType")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SimpleDatePicker
            label={"Visa Expiry Date"}
            value={values.visa?.vExpiry}
            onChange={(newValue) =>
              setValues((prevValues) => ({
                ...prevValues,
                visa: {
                  ...prevValues.visa,
                  vExpiry: newValue,
                },
              }))
            }
            disabled={checkDisable("vExpiry")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"PassPort"}
            file="passportFile"
            onChange={(e) => {
              setPassportFile(e.target.files);
            }}
            
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Visa"}
            file="visaFile"
            onChange={(e) => {
              setVisaFile(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Resume"}
            file="resume"
            onChange={(e) => {
              setResume(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Emirates ID"}
            file="emiratesId"
            onChange={(e) => {
              setEmiratesId(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Pancard"}
            file="pancard"
            onChange={(e) => {
              setPancard(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Aabhar Card"}
            file="setAadharCard"
            onChange={(e) => {
              setAadharCard(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={4} columnSpacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Qualification Certificate
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CustomFileInput
            label={"SSC Certificate"}
            file="setSscDuc"
            onChange={(e) => {
              setSscDuc(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CustomFileInput
            label={"HSC Certificate"}
            file="setHscDuc"
            onChange={(e) => {
              setHscDuc(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CustomFileInput
            label={"Degree Certificate"}
            file="SetDegreeCertificate"
            onChange={(e) => {
              SetDegreeCertificate(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          "@media(max-width:  600px)": {
            gap: 2,
          },
        }}
      >
        <CustomBottom
          onClick={onPrev}
          hoverColor={{ backgroundColor: "#8F9BB3" }}
          style={{
            marginTop: "2rem",
            width: "25%",
            backgroundColor: "#8F9BB3",
            "@media(max-width: 900px)": {
              width: "40%",
              "@media(max-width: 600px)": {
                width: "90%",
              },
            },
          }}
        >
          Previous
        </CustomBottom>
        <CustomBottom
          style={{
            marginTop: "2rem",
            width: "25%",
            "@media(max-width: 900px)": {
              width: "40%",
              "@media(max-width: 600px)": {
                justifyContent: "center",
                width: "90%",
              },
            },
          }}
          onClick={handleSummit}
          loading={loading}
        >
          Submit
        </CustomBottom>
      </Box>
    </>
  );
};

export default UserIdProof;
