import React from "react";
import AuthImg from "../../assets/AuthBack2.svg";
import { Box } from "@mui/material";

const AuthBack = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "98%",
        width: "90%",
        textAlign: "center",
        zIndex: -1,
      }}
    >
      <img
        src={AuthImg}
        style={{ backgroundRepeat: "no-repeat", backgroundPosition: "center !important", backgroundSize: "cover", height: "100%", width: "110%" }}
        alt="background"
      />
    </Box>
  );
};

export default AuthBack;
