import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Apis from "../../utils/apis";
import { toast } from "react-toastify";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        Apis.login,
        { email, password },
        config
      );

      localStorage.setItem("token", data.token);
      localStorage.setItem("info", JSON.stringify(data));
      toast.success("Login successfully");
      return data;
    } catch (error) {
      toast.error(error.response?.data || "Something went wrong");
      return rejectWithValue(error);
    }
  }
);
