import React, { useCallback, useEffect, useState } from "react";
import { Box} from "@mui/material";
// import CustomTextInput from "../../../components/HrManagements/CustomInput";
import { CustomBox } from "../../../components/HrManagements/CustomBox";
import { CommonHeader } from "../../../components/HrManagements/CommonHeader";
// import { CustomBottom } from "../../../components/HrManagements/CustomBottom";
// import CustomSelect from "../../../components/HrManagements/CustomSelect";
// import CustomFileInput from "../../../components/HrManagements/CustomFileInput";
// import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { API_AXIOS } from "../../../http/interceptor";
import Apis from "../../../utils/apis";
// import { toast } from "react-toastify";
import { Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DoneIcon from "@mui/icons-material/Done";
import EmergencyContact from "./EmergencyContact";
import UserIdProof from "./UserIdProof";
import { PersonalInfo } from "./PersonalInfo";
import { useSelector } from "react-redux";
import { isAdmin } from "../../../utils/menuItems";
// import { Sync } from "@mui/icons-material";

/*Stepper Start */
const steps = ["PERSONAL INFORMATION", "EMERGENCY CONTACT", "ID PROOF"];
const ColorStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
    backgroundColor: "#FFC857",
    zIndex: 1,
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    backgroundColor: "#FFC857",
    zIndex: 1,
  },
  [`.${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: "#D9D9D9",
    padding: "3px 0px",
    borderRadius: 10,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: ownerState.active
    ? "green"
    : theme.palette.mode === "dark"
    ? theme.palette.divider[700]
    : "red",
  display: "flex",
  height: 30,
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}));

function QontoStepIcon(props) {
  const { active, completed, className, step } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Box
          sx={{
            backgroundColor: "#FFC857",
            width: "50px",
            height: "50px",
            alignItem: "center",
            textAlign: "center",
            borderRadius: "50%",
            padding: "7px 7px",
          }}
        >
          <div
            className="QontoStepIcon-circle"
            style={{ backgroundColor: "red" }}
          >
            {step}
          </div>
          <DoneIcon style={{ color: "#FFFFFF", fontSize: "2rem" }} />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#8F9BB3",
            width: "50px",
            height: "50px",
            alignItem: "center",
            textAlign: "center",
            borderRadius: "50%",
            padding: "7px 7px",
          }}
        >
          <div className="QontoStepIcon-circle">{step}</div>
          <DoneIcon style={{ color: "#FFFFFF", fontSize: "2rem" }} />
        </Box>
      )}
    </QontoStepIconRoot>
  );
}

export const HorizontalStepper = ({ activeStep }) => {
  return (
    <Box sx={{ width: "100%", marginTop: "2.4rem" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorStepConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              sx={{
                "& .MuiStepLabel-label": {
                  color: "#202020",
                  fontSize: "16px",
                  fontWeight: "600",
                  "@media(max-width: 600px)": {
                    fontSize: "14px",
                  },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

/*Stepper end */

export const UserApplication = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [user, setUser] = useState({});
  const [currentStep, setCurrentSteps] = useState(0);
  const { auth } = useSelector((state) => state.auth);
  const checkAdmin = isAdmin.includes(auth?.role);

  const handleNextStep = () => {
    setCurrentSteps(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentSteps(currentStep - 1);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await API_AXIOS.get(
          `${Apis.getHrmsUserDetails}/${id}`
        );
        setUser(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      getData();
    }
  }, [id]);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomBox style={{ padding: "2rem 2rem" }}>
        <CommonHeader
          title={"User Application"}
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "25px",
          }}
        />
        <HorizontalStepper activeStep={currentStep} />
        {currentStep === 0 && (
          <PersonalInfo
            onNext={handleNextStep}
            onPrev={handlePrevStep}
            isAdmin={checkAdmin}
            user={user}
          />
        )}
        {currentStep === 1 && (
          <EmergencyContact
            onNext={handleNextStep}
            onPrev={handlePrevStep}
            isAdmin={checkAdmin}
            user={user}
          />
        )}
        {currentStep === 2 && (
          <UserIdProof
            onNext={handleNextStep}
            onPrev={handlePrevStep}
            isAdmin={checkAdmin}
            user={user}
          />
        )}
      </CustomBox>
    </Box>
  );
};
