import { Box, Typography, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";
import LoadButton from "../LoadButton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../utils/validation";

const ChangePassword = ({ handleClose }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const [initialValues, setInitialvalues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box sx={{ width: {xs: "15rem", sm: "20rem"}}}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              textAlign: "left",
              lineHeight: "32.81px",
            }}
          >
            Change Password
          </Typography>
          <Typography onClick={handleClose} sx={{ cursor: "pointer" }}>
            {" "}
            <CloseIcon />
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={ChangePasswordSchema}
            onSubmit={async (values) => {
              setLoading(true);
              try {
                const { data } = await API_AXIOS.post(
                  `${Apis.changePassword}/${auth?._id}`,
                  values
                );

                if (data) {
                  toast.success(data);
                  localStorage.clear();
                  navigate(0);
                }
              } catch (error) {
                toast.error(error.response.data);
              }

              setLoading(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  sx={{ marginTop: "2rem" }}
                  label=" Enter the Old Password"
                  size="small"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                />
                <Typography
                  sx={{ color: "red", textAlign: "left", fontSize: "11px" }}
                >
                  {errors.oldPassword &&
                    touched.oldPassword &&
                    errors.oldPassword}
                </Typography>
                <TextField
                  sx={{ marginTop: "2rem" }}
                  label=" Enter New Password"
                  size="small"
                  fullWidth
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                />
                <Typography
                  sx={{ color: "red", textAlign: "left", fontSize: "11px" }}
                >
                  {errors.newPassword &&
                    touched.newPassword &&
                    errors.newPassword}
                </Typography>
                <TextField
                  sx={{ marginTop: "2rem" }}
                  label="Confirm Your  Password"
                  size="small"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  variant="outlined"
                />
                <Typography
                  sx={{ color: "red", textAlign: "left", fontSize: "11px" }}
                >
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </Typography>
                <LoadButton
                  type="submit"
                  color="custom"
                  loading={loading}
                  variant="contained"
                  sx={{
                    mt: "2rem",
                    backgroundColor: "#FFC857",
                    color: "#fff",
                    width: "8rem",
                    borderRadius: "5px",
                  }}
                >
                  Save
                </LoadButton>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
