import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const leaveApi = createApi({
  reducerPath: "leaves",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Leaves"],
  endpoints: (builder) => ({
    getLeavesData: builder.query({
      query: ({
        page = 1,
        search,
        // startDate = "",
        // endDate = "",
      }) => ({
        url: Apis.hrmsLeave,
        params: {
          page,
          search,
          //   startDate,
          //   endDate,
        },
      }),
      providesTags: ["Leaves"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetLeavesDataQuery } = leaveApi;
