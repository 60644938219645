import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const bookingDetailsApi = createApi({
  reducerPath: "bookingDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["BookingDetail"],
  endpoints: (builder) => ({
    getBookingDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.getBookingDetailsById}/${id}`,
      }),
      providesTags: ["BookingDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetBookingDetailsByIdQuery } = bookingDetailsApi;
