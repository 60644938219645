import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../store/reducers/user";
import { getTeams } from "../store/reducers/team";
import { getMeetings } from "../store/reducers/meeting";
import { getBookigs } from "../store/reducers/booking";
import { io } from "socket.io-client";
import { API_AXIOS } from "../http/interceptor";
import Apis from "../utils/apis";
import { logout, setNotifications } from "../store/reducers/auth";
import { getDeveloper } from "../store/reducers/developer";
import { useGetLatestAuthQuery } from "../store/services/authService";
// import { socket } from "../socket";

const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.users);
  const { teams } = useSelector((state) => state.teams);
  const { meetings } = useSelector((state) => state.meetings);
  const { bookings } = useSelector((state) => state.bookings);
  const { developers } = useSelector((state) => state.developers);
  const { data: user } = useGetLatestAuthQuery(auth?._id);

  const getData = useCallback(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
    if (teams.length === 0) {
      dispatch(getTeams());
    }
    if (meetings.length === 0) {
      // dispatch(getMeetings());
    }

    if (developers.length === 0) {
      dispatch(getDeveloper());
    }
  }, [dispatch]);

  const getNotifications = useCallback(async () => {
    try {
      const { data } = await API_AXIOS.get(
        `${Apis.getNotificationById}/${auth?._id}`
      );
      dispatch(setNotifications(data));
    } catch (err) {
      console.log(err.response.data);
    }
  }, [auth, dispatch]);

  const isGetting = useCallback(
    (data) => {
      return (
        auth?.role === "sup_admin" ||
        auth?.role === "sub_admin" ||
        (data?.notifyUsers || [])?.includes(auth && auth._id) ||
        (data?.participents || [])?.includes(auth && auth._id)
      );
    },
    [auth]
  );

  useEffect(() => {
    const socket = io("http://localhost:5000", { reconnection: true });
    socket.connect();

    socket.on("Add Lead", (data) => {
      console.log("Add Lead");
      if (data?.actionBy === auth?._id) return;

      if (isGetting(data)) {
        // dispatch(getLeads());
        getNotifications();
        return;
      }
    });
    socket.on("Assign Lead", (data) => {
      console.log("Assign Lead");
      if (data?.actionBy === auth?._id) return;

      if (isGetting(data)) {
        // dispatch(getLeads());
        getNotifications();
      }
    });
    socket.on("Update Lead", (data) => {
      console.log("Update Lead");
      if (data?.actionBy === auth?._id) return;

      if (isGetting(data)) {
        // dispatch(getLeads());
        getNotifications();
      }
    });
    socket.on("Delete Lead", (data) => {
      if (data?.actionBy === auth?._id) return;
      // dispatch(getLeads());
      console.log("Delete Lead");
    });
    socket.on("Add Meeting", (data) => {
      console.log("Add Meeting");
      if (data?.actionBy === auth?._id) return;

      // if (isGetting(data)) {
      //   // dispatch(getMeetings());
      //   getNotifications();
      // }
    });
    socket.on("Update Meeting", (data) => {
      console.log("Update Meeting");
      if (data?.actionBy === auth?._id) return;

      // if (isGetting(data)) {
      //   // dispatch(getMeetings());
      //   getNotifications();
      // }
    });
    socket.on("Delete Meeting", (data) => {
      if (data?.actionBy === auth?._id) return;

      // dispatch(getMeetings());
      // console.log("Delete Meeting");
    });
    socket.on("Add Booking", (data) => {
      if (data?.actionBy === auth?._id) return;

      console.log("Add Booking");
    });
    socket.on("Update Booking", (data) => {
      if (data?.actionBy === auth?._id) return;
    });
    socket.on("Delete Booking", (data) => {
      if (data?.actionBy === auth?._id) return;
    });
    socket.on("Approve Booking", (data) => {
      if (data?.actionBy === auth?._id) return;

      console.log("Approve Booking");
    });
    socket.on("Reject Booking", (data) => {
      if (data?.actionBy === auth?._id) return;

      console.log("Reject Booking");
    });

    return () => {
      if (socket.connected) {
        console.log("disconnect");
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!auth) {
      sessionStorage.setItem("requestUrl", pathname);
      navigate("/auth/login");
      return;
    } else if (pathname === "/") {
      navigate("/dashboard");
    }

    getData();

    //remove pathname from dependencies due to extra api calls
  }, [auth, navigate, getData]);

  if (!auth) {
    navigate("/auth/login");
    return null;
  }

  // if (user?.email && user?.email === "mohdkashif1008@gmail.com") {
  //   localStorage.clear();
  //   dispatch(logout());
  //   navigate("/");
  // }

  // if(user && user.activeStatus !== "onboard") {
  if (user && ["resign", "terminated"].includes(user.activeStatus)) {
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  }

  return (
    <Box>
      <Header />
      <Navbar />
      <Box sx={{ px: "1.5rem", pb: 4 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
