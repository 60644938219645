import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Typography } from "@mui/material";

const SimpleDatePicker = ({
  style,
  InputProps,
  label,
  labelStyle,
  placeholder = "Date",
  minDate,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Typography
          sx={{
            color: "#000000",
            fontWeight: "600",
            fontSize: "15px",
            marginBottom: "2px",
            "@media(max-width:600px)": {
              fontSize: "13px",
            },
            ...labelStyle,
          }}
        >
          {label}
        </Typography>
        <DatePicker
          sx={{
            width: "100%",
            backgroundColor: "#FDFDF9",
            "& .MuiOutlinedInput-root": {
              borderRadius: "7.5px",
              height: "35px",
              backgroundColor: "#FCFAFA",
              "&:hover fieldset": {
                border: "1px solid #DCDCDC",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid #DCDCDC",
              },
              ...style,
            },
          }}
          InputProps={{ ...InputProps, placeholder: placeholder }}
          defaultHighlight="From"
          minDate={minDate}
          {...props}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default SimpleDatePicker;
