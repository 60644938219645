import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomTextInput from "../../../components/HrManagements/CustomInput";
import CustomFileInput from "../../../components/HrManagements/CustomFileInput";
import { Formik, useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { API_AXIOS_MULTIPART } from "../../../http/interceptor";
import Apis from "../../../utils/apis";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import SimpleDatePicker from "../../../components/HrManagements/SimpleDatePicker";
import { CustomBottom } from "../../../components/HrManagements/CustomBottom";
import CustomSelect from "../../../components/HrManagements/CustomSelect";
import CheckBox from "../../../components/HrManagements/CustomCheckBox";
import { PhoneInput } from "react-international-phone";

export const PersonalInfo = ({ onNext, user, isAdmin }) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const name = searchParams.get("name");
  const lastName = searchParams.get("lastName");
  const [bankStatements, setBankStatements] = useState([]);
  const [addressProof, setAddressProof] = useState([]);
  const [copyCurrentToPermanent, setCopyCurrentToPermanent] = useState(false);

  const { values, setFieldValue, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        name: name || "",
        lastName: lastName || "",
        DOB: dayjs(user?.DOB) || "",
        mobile: "",
        currentAddress: {
          currentAdd: "",
          currentCity: "",
          currentCountry: "",
          currentPostCode: "",
        },
        permAddress: {
          permAdd: "",
          permCity: "",
          permCountry: "",
          permPostCode: "",
        },
        bankDetails: {
          nameOnBank: "",
          bankName: "",
          sortCode: "",
          accountNumber: "",
        },
      },
      onSubmit: async (values) => {
        let data = {};
        user?.isEditable?.forEach((el) => {
          if (!!values[el]) {
            data[el] = values[el];
          } else {
            let noKey = ["currentAddress", "permAddress", "bankDetails"];
            noKey?.forEach((x) => {
              for (const key in values[x]) {
                if (el === key) {
                  let dd = {
                    [el]: values[x][key],
                  };
                  data[x] = { ...data[x], ...dd };
                }
              }
            });
          }
        });
        let updatingData = !user?.isFilled || isAdmin ? values : data;
        const formData = new FormData();
        formData.append("data", JSON.stringify(updatingData));
        formData.append("token", token);
        const addressProofArr = Object?.values(addressProof) || [];
        const bankStatementsArr = Object?.values(bankStatements) || [];

        addressProofArr?.forEach((proof) => {
          formData.append("addressProof", proof);
        });

        bankStatementsArr?.forEach((statement) => {
          formData.append("bankStatements", statement);
        });

        try {
          setLoading(true);
          const { data } = await API_AXIOS_MULTIPART.post(
            `${Apis.updateUser}/${id}`,
            formData
          );
          onNext();
        } catch (err) {
          console.log(err);
          toast.error(err.response.data);
        }
        setLoading(false);
      },
    });

  useEffect(() => {
    if (user) {
      setFieldValue("name", user?.name || "");
      setFieldValue("lastName", user?.lastName || "");
      setFieldValue("DOB", dayjs(user?.DOB) || "");
      setFieldValue("mobile", user?.mobile || "");
      if (user.currentAddress) {
        setFieldValue(
          "currentAddress.currentAdd",
          user.currentAddress.currentAdd || ""
        );
        setFieldValue(
          "currentAddress.currentCity",
          user.currentAddress.currentCity || ""
        );
        setFieldValue(
          "currentAddress.currentCountry",
          user.currentAddress.currentCountry || ""
        );
        setFieldValue(
          "currentAddress.currentPostCode",
          user.currentAddress.currentPostCode || ""
        );
      }
      if (user.permAddress) {
        setFieldValue("permAddress.permAdd", user.permAddress.permAdd || "");
        setFieldValue("permAddress.permCity", user.permAddress.permCity || "");
        setFieldValue(
          "permAddress.permCountry",
          user.permAddress.permCountry || ""
        );
        setFieldValue(
          "permAddress.permPostCode",
          user.permAddress.permPostCode || ""
        );
      }
      if (user?.bankDetails) {
        setFieldValue(
          "bankDetails.nameOnBank",
          user.bankDetails.nameOnBank || ""
        );
        setFieldValue("bankDetails.bankName", user.bankDetails.bankName || "");
        setFieldValue("bankDetails.sortCode", user.bankDetails.sortCode || "");
        setFieldValue(
          "bankDetails.accountNumber",
          user.bankDetails.accountNumber || ""
        );
      }
    }
  }, [setFieldValue, user]);

  const handleCheckBoxChange = (event) => {
    const isChecked = event.target.checked;
    setCopyCurrentToPermanent(isChecked);
    if (isChecked) {
      let { currentAdd, currentCity, currentCountry, currentPostCode } =
        values?.currentAddress;
      setFieldValue("permAddress", {
        permAdd: currentAdd,
        permCity: currentCity,
        permCountry: currentCountry,
        permPostCode: currentPostCode,
      });
    } else {
      setFieldValue("permAddress", {
        permAdd: "",
        permCity: "",
        permCountry: "",
        permPostCode: "",
      });
    }
  };

  const checkDisable = (key) => {
    if (isAdmin) return false;
    return user?.isFilled && !user?.isEditable?.includes(key);
  };

  const handlePhoneInputChange = (phone) => {
    setFieldValue("mobile", phone);
  };

  

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container rowSpacing={2} columnSpacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            PERSONAL INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"First Name"}
            name="name"
            placeholder={"First Name"}
            value={values.name}
            onChange={handleChange}
            disabled={checkDisable("name")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Last Name"}
            placeholder={"Last Name"}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            disabled={checkDisable("lastName")}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Mobile Number"}
            name="mobile"
            placeholder={"Mobile Number"}
            value={values.mobile}
            onChange={handleChange}
            disabled={checkDisable("mobile")}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          

          <>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "15px",
                marginBottom: "2px",
                "@media(max-width:600px)": {
                  fontSize: "13px",
                },
              }}
            >
              Mobile Number
            </Typography>
            <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              defaultCountry="ae"
              placeholder="Mobile Number"
              value={values.mobile}
              onChange={handlePhoneInputChange}
              disabled={checkDisable("mobile")}
            />
            {/* {phoneError && (
              <Typography
                sx={{ color: "red", fontSize: "12px", marginTop: "4px" }}
              >
                {phoneError}
              </Typography>
            )} */}
          </>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
                  <CustomSelect
                    //value={formData.srManager}
                    name="role"
                    // handleChange={handleFormChange}
                    //handleBlur={handleBlur}
                    label="Role"
                    items={selectRole}
                  />
                </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          <SimpleDatePicker
            label={"D.O.B"}
            value={values.DOB}
            onChange={(date) => {
              setFieldValue("DOB", date);
            }}
            disabled={checkDisable("DOB")}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={4} mt={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            CURRENT ADDRESS
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTextInput
            label={"Current Address "}
            name="currentAddress.currentAdd"
            placeholder={"House.no./Flat.no/Bulding Name"}
            value={values.currentAddress.currentAdd}
            onChange={handleChange}
            disabled={checkDisable("currentAdd")}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12}>
                <CustomTextInput
                  label={"Permanent Address"}
                  name="address.permAdd"
                  placeholder={"Address Line2"}
                  value={values.address.permAdd}
                  onChange={handleChange}
                  disabled={
                    user?.isFilled && !user?.isEditable?.includes("permAdd")
                  }
                />
              </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"City"}
            name="currentAddress.currentCity"
            placeholder={"City"}
            value={values.currentAddress.currentCity}
            onChange={handleChange}
            disabled={checkDisable("currentCity")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Country"}
            name="currentAddress.currentCountry"
            placeholder={"Country"}
            value={values.currentAddress.currentCountry}
            onChange={handleChange}
            disabled={checkDisable("currentCountry")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Postal Code"}
            name="currentAddress.currentPostCode"
            placeholder={"Postal Code"}
            value={values?.currentAddress?.currentPostCode}
            onChange={handleChange}
            disabled={checkDisable("currentPostCode")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomFileInput
            label={"Address Proof"}
            file="addressProof"
            onChange={(e) => {
              setAddressProof(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("addressProof")
            // }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={4} mt={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ display: "flex", gap: 10, "@media(max-width: 600px)":{
              flexDirection: "column",
              gap: "2px"
          } }}>
            <Typography
              sx={{
                color: "#202020",
                fontSize: "20px",
                fontWeight: "600",
                
              }}
            >
              PERMAMENT ADDRESS
            </Typography>
            <CheckBox
              label="Same As Current Address"
              checked={copyCurrentToPermanent}
              disabled={
                user?.isFilled &&
                (!user?.isEditable ||
                  !user.isEditable.includes("currentAdd") ||
                  !user.isEditable.includes("currentCity") ||
                  !user.isEditable.includes("permAdd") ||
                  !user.isEditable.includes("permCity"))
              }
              onChange={handleCheckBoxChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomTextInput
            label={"Permanent Address"}
            name="permAddress.permAdd"
            placeholder={"Permanent Address"}
            value={values?.permAddress?.permAdd}
            onChange={handleChange}
            disabled={checkDisable("permAdd")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"City"}
            name="permAddress.permCity"
            placeholder={"City"}
            value={values?.permAddress?.permCity}
            onChange={handleChange}
            disabled={checkDisable("permCity")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Country"}
            name="permAddress.permCountry"
            placeholder={"Country"}
            value={values?.permAddress?.permCountry}
            onChange={handleChange}
            disabled={checkDisable("permCountry")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Postal Code"}
            name="permAddress.permPostCode"
            placeholder={"Postal Code"}
            value={values?.permAddress?.permPostCode}
            onChange={handleChange}
            disabled={checkDisable("permPostCode")}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
                <CustomFileInput
                  label={"Address Proof"}
                  file="addressProof"
                  onChange={(e) => {
                    setAddressProof(e.target.files);
                  }}
                  // disabled={
                  //   user?.isFilled &&
                  //   !user?.isEditable?.includes("addressProof")
                  // }
                />
              </Grid> */}
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={4} mt={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{
              color: "#202020",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            BANK INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Bank Name"}
            name="bankDetails.bankName"
            placeholder={"Enter Bank Name"}
            value={values.bankDetails.bankName}
            onChange={handleChange}
            disabled={checkDisable("bankName")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Name on Bank Account"}
            name="bankDetails.nameOnBank"
            placeholder={"Enter Name on Bank Account"}
            value={values.bankDetails.nameOnBank}
            onChange={handleChange}
            disabled={checkDisable("nameOnBank")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"Account Number"}
            placeholder={"Account Number"}
            name="bankDetails.accountNumber"
            value={values.bankDetails.accountNumber}
            onChange={handleChange}
            disabled={checkDisable("accountNumber")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextInput
            label={"IBAN"}
            placeholder={"IBAN"}
            name="bankDetails.sortCode"
            value={values.bankDetails.sortCode}
            onChange={handleChange}
            disabled={checkDisable("sortCode")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomFileInput
            label={"Salary Slip or Bank Statement(Previous Organisation)"}
            file="bankStatements"
            onChange={(e) => {
              setBankStatements(e.target.files);
            }}
            // disabled={
            //   user?.isFilled &&
            //   !user?.isEditable?.includes("bankStatements")
            // }
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "right" }}>
        <CustomBottom
          style={{
            marginTop: "2rem",
            width: "25%",
            "@media(max-width: 900px)": {
              width: "40%",
              "@media(max-width: 600px)": {
                justifyContent: "center",
                width: "100%",
              },
            },
          }}
          onClick={handleSubmit}
          loading={loading}
        >
          Next
        </CustomBottom>
      </Box>
    </Box>
  );
};
