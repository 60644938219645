import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const meetingDetailsApi = createApi({
  reducerPath: "meetingDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["MeetingDetail"],
  endpoints: (builder) => ({
    getMeetingDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.meetingDetailsById}/${id}`,
      }),
      providesTags: ["MeetingDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetMeetingDetailsByIdQuery } = meetingDetailsApi;
