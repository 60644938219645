import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../utils/apis";

export const notificationApi = createApi({
  reducerPath: "notifications",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (url) => url,
      providesTags: ["Notifications"],
      transformResponse: (res) => res.data,
      keepUnusedDataFor: 3000
    }),
  }),
});

export const { useGetNotificationsQuery } = notificationApi;
