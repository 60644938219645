import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const leadsApi = createApi({
  reducerPath: "leadList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Leads"],
  endpoints: (builder) => ({
    getLeadsData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status,
        individual,
        search,
        pagination = true,
        srManager,
        manager,
        assistantManager,
        teamLead,
        agent,
        startDate,
        endDate,
        skipType,
      }) => ({
        url: Apis.leads,
        params: {
          page,
          limit,
          type,
          status,
          individual,
          search,
          pagination,
          srManager,
          manager,
          assistantManager,
          teamLead,
          agent,
          startDate,
          endDate,
          skipType,
        },
      }),
      providesTags: ["Leads"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetLeadsDataQuery } = leadsApi;
