import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";

const getDashboardMeeting = async () => {
  try {
    const { data } = await API_AXIOS.get(Apis.meetingDashboard);
    return { data };
  } catch (error) {
    return {
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const dashboardMeetingApi = createApi({
  reducerPath: "dashboardMeeting",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboardMeeting: builder.query({
      queryFn: getDashboardMeeting,
      providesTags: ["Dashboard"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetDashboardMeetingQuery } = dashboardMeetingApi;
