import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const assignUsersAPi = createApi({
  reducerPath: "assignUsers",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Assign"],
  endpoints: (builder) => ({
    getAssignUsers: builder.query({
      query: (srManager) => ({
        url: `${Apis.assignUsers}`,
        params: {
          srManager,
        },
      }),
      providesTags: ["Assign"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetAssignUsersQuery } = assignUsersAPi;
