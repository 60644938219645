import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";
import { jsonSort } from "../../utils/commonMethods";

const initialState = {
  developers: [],
  developersLoading: false,
};

export const getDeveloper = createAsyncThunk(
  "developerList",
  async (thunkApi) => {
    try {
      const { data } = await API_AXIOS.get(Apis.developer);

      return jsonSort(data, "updatedAt");
    } catch (err) {
      console.log(err);
      return [];
    }
  }
);

export const developerSlice = createSlice({
  name: "developer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDeveloper.pending, (state) => {
      state.developersLoading = true;
    });
    builder.addCase(getDeveloper.fulfilled, (state, { payload }) => {
      state.developersLoading = false;
      state.developers = payload;
    });
    builder.addCase(getDeveloper.rejected, (state) => {
      state.developersLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default developerSlice.reducer;
