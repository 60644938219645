import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import { jsonSort } from "../../utils/commonMethods";

const initialState = {
  advanceSearch: [],
  advanceSearchLoading: false,
};

export const getAdvanceSearch = createAsyncThunk(
  "lead/filterLeads",
  async ({ apiEndpoint, values }) => {
    try {
      const { data } = await API_AXIOS.post(apiEndpoint, values);
      return jsonSort(data, "updatedAt");
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export const advanceSearchSlice = createSlice({
  name: "advanceSearch",
  initialState,
  reducers: {
    clearAdvanceSearch: (state) => {
      state.advanceSearch = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdvanceSearch.pending, (state) => {
      state.advanceSearchLoading = true;
    });
    builder.addCase(getAdvanceSearch.fulfilled, (state, { payload }) => {
      state.advanceSearchLoading = false;
      state.advanceSearch = payload;
    });
    builder.addCase(getAdvanceSearch.rejected, (state) => {
      state.advanceSearch = false;
    });
  },
});

export const { clearAdvanceSearch } = advanceSearchSlice.actions;
export default advanceSearchSlice.reducer;
