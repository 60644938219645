import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";

export const creditLeavesApi = createApi({
  reducerPath: "creditData",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["CreditData"],
  endpoints: (builder) => ({
    getCreditLeaves: builder.query({
      query: () => "/hrms/dashboard/creditleaves",
      providesTags: ["CreditData"],
      transformResponse: (res) => res.data,
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetCreditLeavesQuery } = creditLeavesApi;
