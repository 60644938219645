import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";
import { jsonSort } from "../../utils/commonMethods";

const initialState = {
  leads: [],
  leadsLoading: false,
};

export const getLeads = createAsyncThunk("leadList", async (thunkApi) => {
  try {
    const { data } = await API_AXIOS.get(Apis.leads);

    return jsonSort(data, "updatedAt");
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.leadsLoading = true;
    });
    builder.addCase(getLeads.fulfilled, (state, { payload }) => {
      state.leadsLoading = false;
      state.leads = payload;
    });
    builder.addCase(getLeads.rejected, (state) => {
      state.leadsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default leadSlice.reducer;
