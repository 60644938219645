import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../utils/apis";

export const baseQueryWithAuth = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // Retrieve the token from wherever you have stored it in your Redux store
    const token = getState().auth.auth.token;

    // If a token exists, add it to the headers
    if (token) {
      headers.set("token", `Bearer ${token}`);
    }

    return headers;
  },
});

export const hrmsNotificationApi = createApi({
  reducerPath: "hrmsNotifications",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["HrmsNotifications"],
  endpoints: (builder) => ({
    getHrmsNotifications: builder.query({
      query: (url) => url,
      providesTags: ["HrmsNotifications"],
      transformResponse: (res) => res.data,
      keepUnusedDataFor: 300
    }),
  }),
});

export const { useGetHrmsNotificationsQuery } = hrmsNotificationApi;
