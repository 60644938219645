import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Protected = () => {
  const navigate = useNavigate();
  const { pathname,search } = useLocation();
  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth) {
      sessionStorage.setItem("requestUrl", pathname + search);
      navigate("/auth/login");
      return;
    }
  }, [auth]);

  if (!auth) return null;

  return <Outlet />;
};

export default Protected;
