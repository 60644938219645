import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import BasicSelect from "../BasicSelect";
import LoadButton from "../LoadButton";
import { jsonSort, teamUsers } from "../../utils/commonMethods";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuery } from "../../store/reducers/query";
import {
  isAdmin,
  isAdminSrmanager,
  leadType,
  status,
} from "../../utils/menuItems";
import Apis from "../../utils/apis";
import dayjs from "dayjs";
import CustomRadio from "../CustomRadio";

const LeadAdForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.users);
  const { teams } = useSelector((state) => state.teams);
  const { query } = useSelector((state) => state.query);
  const agent = auth?.role === "agent";
  const teamLeadAgent = auth?.role === "team_lead" || auth?.role === "agent";
  const AMTeamLeadAgent = teamLeadAgent || auth?.role === "assistant_manager";
  const ManagerAMTeamLeadAgent = AMTeamLeadAgent || auth?.role === "manager";
  const SrmManagerAMTeamLeadAgent =
    ManagerAMTeamLeadAgent || auth?.role === "sr_manager";

  const [values, setValues] = useState({
    // type: query?.type ? query?.type : "lead",
    status: query?.status ? query?.status : "",
    startDate: query?.startDate
      ? dayjs(query?.startDate).startOf("day")
      : dayjs(Date.now()).subtract(2, "days").startOf("day"),
    endDate: query?.startDate
      ? dayjs(query?.endDate).endOf("day")
      : dayjs(Date.now()),
    srManager: query?.srManager ? query?.srManager : "",
    manager: query?.manager ? query?.manager : "",
    assistantManager: query?.assistantManager ?? "",
    teamLead: query?.teamLead ? query?.teamLead : "",
    agent: query?.agent ? query?.agent : "",
    individual: query?.individual ?? "false",
  });

  const filterUsers = useCallback(
    (value) => {
      return jsonSort(
        teamUsers(teams, users)?.filter((user) =>
          user.role === value ? true : false
        ),
        "name",
        true
      ).map((user) => ({ value: user._id, label: user.name }));
    },
    [teams, users]
  );

  return (
    <Formik
      initialValues={values}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          dispatch(setQuery({ values, type: "lead" }));
          // setLoading(false);
          // if (type === `lead/filterLeads/fulfilled`) {
          navigate(`/leads`);
          //   //popup Close
          handleClose();
          // }
        } catch (err) {
          console.log("err", err);
        }
        setLoading(false);
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={3} mt={0}>
            {![...isAdmin, "agent"].includes(auth?.role) && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Teams Option
                </Typography>
                <CustomRadio
                  items={[
                    { value: true, label: "My Leads" },
                    { value: false, label: "Teams Lead" },
                  ]}
                  value={values.individual}
                  handleChange={handleChange}
                  name={"individual"}
                  size={"small"}
                  row
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Start Date
              </Typography>
              <DatePicker
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
                sx={{ width: "100%" }}
                placeholder="start"
                format="DD/MM/YYYY"
                slotProps={{
                  field: {
                    size: "small",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                End Date
              </Typography>
              <DatePicker
                value={values.endDate}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
                sx={{ width: "100%" }}
                placeholder="start"
                format="DD/MM/YYYY"
                slotProps={{
                  field: {
                    size: "small",
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Type
              </Typography>
              <BasicSelect
                value={values.type}
                name={"type"}
                size="small"
                handleChange={handleChange}
                items={leadType}
              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Status
              </Typography>
              <BasicSelect
                value={values.status}
                name={"status"}
                size="small"
                handleChange={handleChange}
                items={status}
              />
            </Grid>

            {console.log("207", values.individual)}
            {values.individual === "false" && (
              <>
                {isAdminSrmanager.includes(auth?.role) && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        mb: 1,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Sr Manager
                    </Typography>
                    <BasicSelect
                      value={values.srManager}
                      name={"srManager"}
                      size="small"
                      handleChange={handleChange}
                      items={filterUsers("sr_manager")}
                    />
                  </Grid>
                )}

                {!ManagerAMTeamLeadAgent && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        mb: 1,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Team Manager
                    </Typography>
                    <BasicSelect
                      value={values.manager}
                      name={"manager"}
                      size="small"
                      handleChange={handleChange}
                      items={filterUsers("manager")}
                    />
                  </Grid>
                )}

                {!AMTeamLeadAgent && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        mb: 1,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Assistant Manager
                    </Typography>
                    <BasicSelect
                      value={values.assistantManager}
                      name={"assistantManager"}
                      size="small"
                      handleChange={handleChange}
                      items={filterUsers("assistant_manager")}
                    />
                  </Grid>
                )}

                {!teamLeadAgent && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        mb: 1,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Team Lead
                    </Typography>
                    <BasicSelect
                      value={values.teamLead}
                      name={"teamLead"}
                      size="small"
                      handleChange={handleChange}
                      items={filterUsers("team_lead")}
                    />
                  </Grid>
                )}
                {!agent && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      sx={{
                        fontSize: 18,
                        mb: 1,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Agents
                    </Typography>
                    <BasicSelect
                      value={values.agent}
                      name={"agent"}
                      size="small"
                      handleChange={handleChange}
                      items={filterUsers("agent")}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <LoadButton
                loading={loading}
                type="submit"
                variant="contained"
                color="custom"
                sx={{ width: "120px", marginBottom: "15px" }}
              >
                Apply
              </LoadButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LeadAdForm;
