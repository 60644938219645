import React from 'react'
import { Box } from '@mui/material'

export const CustomBox = ({style, children}) => {
  return (
    <Box
      sx={{
        boxShadow: "3px 4px 10px 0px #00000012",
        border: "0.9px solid #EFF4FA",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        p: 2,
        "@media(max-width: 600px)":{
           padding: "10px"
        },
        ...style
      }}
    >
    {children}
     
    </Box>
  )
}
